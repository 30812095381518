@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .custom-lt {
        text-underline-offset: -0.28rem;
        text-decoration-skip-ink: none;
    }

    .e-de-pagenumber-input {
        padding: 0;
    }

    .e-de-status-bar {
        align-items: center;
    }

    .e-de-ctnr-pg-no-spellout {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@layer utilities {
    .menu-item-active-style {
        @apply border-primary-100 sidebar-expanded:bg-mirage sidebar-expanded:text-slate-400 text-indigo-500;
    }

    .parent-div:has(button[aria-expanded="true"]) {
        @apply visible;
    }
}

@layer components {
    .btn,
    .btn-lg,
    .btn-sm,
    .btn-xs {
        @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
    }

    .btn {
        @apply px-3 py-2;
    }

    .btn-lg {
        @apply px-4 py-3;
    }

    .btn-sm {
        @apply px-2 py-1;
    }

    .btn-xs {
        @apply px-2 py-0.5;
    }

    .uisr-page-wrapper {
        @apply flex h-[100svh] overflow-hidden bg-slate-100 text-slate-600;
    }

    .uisr-content-area {
        @apply relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden transition-colors duration-200;
    }

    .uisr-backdrop {
        @apply fixed inset-0 bg-slate-900 bg-opacity-30 z-60 transition-opacity duration-200;
    }

    .uisr-modal-backdrop {
        @apply fixed inset-0 bg-slate-900 dark:bg-slate-900 bg-opacity-30 transition-opacity duration-200 z-[1000];
    }

    .uisr-btn-8 {
        @apply w-8 h-8 flex items-center justify-center transition duration-200 rounded-full;
    }

    .uisr-dropdown-menu {
        @apply bg-pearl-100 border border-slate-200 shadow-lg min-w-44 rounded-xl;
    }

   .dropdown-menu {
        @apply bg-pearl-100 border border-slate-200 shadow-lg min-w-44 rounded-xl;
   }

    .uisr-outer-side-panel {
        @apply absolute inset-0 sm:left-auto z-50 transition-transform duration-200 ease-in-out translate-x-0;
    }

    .uisr-title-text {
        @apply text-2xl text-slate-800 font-bold;
    }

    .uisr-primary-button {
        @apply nightwind-prevent btn bg-primary-100 hover:border-primary-200 text-slate-100 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none;
    }

    .checkbox-primary {
        @apply text-slate-100 shadow-none;
    }

    .uisr-secondary-button {
        @apply btn border-slate-200 hover:border-slate-300 text-slate-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed disabled:pointer-events-none shadow-none;
    }

    .uisr-secondary-button-sm {
        @apply btn-sm border-slate-200 hover:border-slate-300 text-slate-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed disabled:pointer-events-none shadow-none;
    }

    .uisr-accent-button {
        @apply btn border-slate-200 text-primary-100 hover:border-slate-300 shadow-sm disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed;
    }

    .uisr-page-title {
        @apply text-2xl md:text-3xl text-slate-800 dark:text-slate-400 font-bold;
    }

    .current-selection {
        @apply bg-indigo-50 hover:bg-indigo-50 dark:hover:bg-slate-900 dark:bg-slate-900 dark:text-primary-100 text-primary-100 hover:text-primary-100 dark:hover:text-primary-100;
    }

    .app-custom-panel {
        @apply relative bg-pearl-100 rounded shadow-lg overflow-hidden max-w-[90%] max-h-[95svh] m-auto z-0;
    }

    .app-fullscreen-panel {
        @apply relative w-svw h-svh z-0;
    }

    .cropper-panel {
        @apply relative;
    }

    .app-custom-backdrop {
        @apply bg-black bg-opacity-50;
    }

    .app-menu-panel {
        @apply min-w-[4.5rem] uisr-dropdown-menu;
    }

    .app-dialog-title {
        @apply bg-pearl-100 font-semibold text-primary-100 flex items-center justify-between gap-4 mb-2;
    }

    .app-menu-option {
        @apply font-medium text-sm text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start py-1 px-3 hover:bg-slate-50 disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full;
    }

    .app-menu-option-danger {
        @apply font-medium text-sm text-rose-600 hover:text-red-600 dark:hover:text-primary-200 flex items-center justify-start py-1 px-3 hover:bg-slate-50 disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full;
    }

    .secondary-icon {
        --fa-primary-color: #94a3b8;
        --fa-primary-opacity: 1;
        --fa-secondary-color: #dadada;
        --fa-secondary-opacity: 1;
    }

    .primary-icon {
        --fa-primary-color: #817df2;
        --fa-secondary-color: #94a3b8;
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
    }

    .bookmark-primary-icon {
        --fa-primary-color: #817df2;
        --fa-secondary-color: #dadada;
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
    }

    .report-icon {
        --fa-primary-color: #1e293b;
        --fa-secondary-color: #1e293b;
        --fa-primary-opacity: 0.9;
        --fa-secondary-opacity: 0.2;
    }

    .report-icon-hover {
        --fa-primary-color: #1e293b;
        --fa-secondary-color: #ffbe1a;
        --fa-primary-opacity: 0.95;
        --fa-secondary-opacity: 0.6;
    }

    .error-icon {
        --fa-primary-color: #f43f5e;
        --fa-secondary-color: #f43f5e;
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 0.2;
    }

    .custom-overlay-panel {
        @apply max-w-[100svw];
    }
}

@import url("./additional-styles/utility-patterns.scss");
